<template>
  <div class="course-module">
    <div class="d-flex justify-content-between">
      <div class="mt-2">
        <h6 class="text-primary">
          Semester/Term Details
          <span class="h6">
            [{{ semesterInfo.length || "0" }} record{{
              semesterInfo.length > 1 ? "s" : ""
            }}]</span
          >
        </h6>
      </div>
      <div>
        <CButton
          class="btn-primary small mr-2"
          style="height: 28px"
          v-c-tooltip="'Click to Add new module!'"
          v-if="!isEdit"
          @click="addNewSemester"
          >Add</CButton
        >
        <span
          class="btn action-button mx-2"
          v-c-tooltip="{ content: 'Save', placement: 'left' }"
          v-if="isEdit"
          @click="submitSemester"
        >
          <CIcon
            name="cil-save"
            class="active text-primary"
            style="margin-right: 20px"
          />
        </span>
        <span
          class="btn action-button mx-2"
          v-c-tooltip="{ content: 'Cancel', placement: 'left' }"
          v-if="isEdit"
          @click="cancelEdit"
        >
          <CIcon
            name="cil-x-circle"
            class="active text-primary"
            style="margin-right: 20px"
          />
        </span>
      </div>
    </div>
    <div v-if="isEdit" class="border-bottom" ref="form">
      <ValidationObserver ref="semesterForm" v-slot="{ handleSubmit }">
        <form id="semesterForm" @submit.prevent="handleSubmit(onSubmit)">
          <CRow>
            <CCol md="4">
              <CRow class="mb-3">
                <label class="col-lg-12 col-md-12 required">Semester No.</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <Select
                      name="semester_no"
                      :value="editSemester.semester_no"
                      :options="
                      options && options['semisterNumbers'] ? options['semisterNumbers'] : []
                    "
                   
                    @change="handleChangeSelect"
                    :multiple="false"
                    :clearable="true"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
            </CCol>
            <CCol md="4">
              <CRow class="mb-3">
                <label class="col-lg-12 col-md-12 required">Start Date</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <DatePicker
                      name="start_date"
                      :value="editSemester.start_date"
                      @input="handleDatePicker"
                      :error="errors[0]"
                      format="DD-MM-YYYY"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
            </CCol>
            <CCol md="4">
              <CRow class="mb-3">
                <label class="col-lg-12 col-md-12">End Date</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <DatePicker
                      name="end_date"
                      :value="editSemester.end_date"
                      @input="handleDatePicker"
                      format="DD-MM-YYYY"
                    />
                </div>
              </CRow>
            </CCol>
            <CCol md="12">
              <CRow class="mb-3">
                <label class="col-lg-12 col-md-12">Description</label>
                <div class="col-lg-12 col-md-12">
                  <TextAreaInput
                    name="description"
                    :value="editSemester.description"
                    @input="handleInput"
                  />
                </div>
              </CRow>
            </CCol>
          </CRow>
        </form>
      </ValidationObserver>
    </div>
    <div class="multi-records">
      <div
        v-for="(item, index) in semesterInfo"
        :key="index"
        class="record border-dotted-bottom px-3 pt-1"
      >
        <div class="d-flex justify-content-between">
          <div class="w-98">
            <CRow>
              <CCol md="4">
                <p class="head">Semester No.</p>
                <p class="data">{{ item.semester_no || "--" }}</p>
              </CCol>
              <CCol md="4">
                <p class="head">Start Date</p>
                <p class="data">{{ item.start_date | formatDate }}</p>
              </CCol>
              <CCol md="4">
                <p class="head">End Date</p>
                <p class="data">{{ item.end_date | formatDate }}</p>
              </CCol>
              <CCol md="4">
                <p class="head">Description</p>
                <p
                  class="data text-truncate"
                  v-c-tooltip="{
                    content: `${item.description}`,
                    placement: 'left',
                  }"
                >
                  {{ item.description || "--" }}
                </p>
              </CCol>
            </CRow>
          </div>
          <div class="text-center">
            <CButton
              class="icon icon-edit mb-2"
              v-c-tooltip="{ content: 'Edit', placement: 'left' }"
              @click="enableEditSemester(item)"
            >
            </CButton>
            <!-- <CButton
              class="icon"
              v-c-tooltip="{ content: 'Delete', placement: 'left' }"
              @click="deleteModule(item)"
            >
              <i class="fas fa-trash-alt" />
            </CButton> -->
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-if="deleteConfirmModal.isShowPopup"
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import Vue from "vue";
import m from "moment";
import CourseViewMixin from "@/containers/Course/ViewCourse/CourseViewMixin.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CourseViewSemester",
  mixins: [CourseViewMixin],
  props: {
    semesterInfo: {
      type: Array,
      default: () => [],
    },
    courseId: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      isEdit: false,
      editSemester: null,
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        data: null,
      },
    };
  },
  computed: {
    options() {
      return {
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
        courseDeliveryMode: this.courseDeliveryMode,
        currency: this.getCurrency,
        trainingTypes: this.trainingTypes,
        semisterNumbers : this.semNumbers
      };
    },
    semNumbers() {
      const points = Array.from({ length: 250 }, (_, i) => i + 1).map((v) => ({
        code: v,
        label: v,
      }));
      return points;
    },
  },
  methods: {
    ...mapActions(["updateCourseSemester", "createCourseSemester"]),
    submitSemester() {
      this.tooltipHide();
      const payload = {
        ...this.editSemester,
        training_course_id: this.courseId,
        start_date: m(this.editSemester.start_date).format("YYYY-MM-DD"),
        end_date: m(this.editSemester.end_date).format("YYYY-MM-DD"),
      };
      const { course_semester_id } = this.editSemester;
      let appendAction = [];
      if (course_semester_id) {
        appendAction = [
          this.updateCourseSemester({ payload, course_semester_id }),
        ];
      } else {
        appendAction = [this.createCourseSemester(payload)];
      }
      Promise.all(appendAction).then((res) => {
        this.cancelEdit();
      });
    },
    addNewSemester() {
      this.editSemester = {};
      this.isEdit = true;
      this.tooltipHide();
    },
    cancelEdit() {
      this.isEdit = false;
      this.tooltipHide();
      this.editSemester = null;
    },
    enableEditSemester(data) {
      this.tooltipHide();
      this.editSemester = {
        ...data,
        start_date: m(data.start_date, "YYYY-MM-DD").toDate(),
        end_date: m(data.end_date, "YYYY-MM-DD").toDate(),
      };
      this.isEdit = true;
      setTimeout(() => {
        this.$refs.form?.scrollIntoView({ behavior: "smooth" });
      }, 10);
    },
    deleteSemester(data) {
      this.deleteConfirmModal.modalTitle = "NOTIFICATION";
      this.deleteConfirmModal.modalContent =
        "Are you sure you want to delete this Semester?";
      this.deleteConfirmModal.isShowPopup = true;
      this.deleteConfirmModal.data = data;
    },
    deleteModalCallBack(action) {
      if (action === "Confirm") {
        const { data } = this.deleteConfirmModal;
      }
      this.deleteConfirmModal.isShowPopup = false;
      this.deleteConfirmModal.data = null;
    },
    handleDatePicker(name, value) {
      Vue.set(this.editSemester, name, value);
    },
    handleInput(name, value) {
      Vue.set(this.editSemester, name, value);
    },
    handleRadioButton(name, value) {
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.editSemester, name, val);
    },
    handleChangeSelect(name, value) {
      let val = value?.code;
      if(name === "semester_no"){
        val = parseInt(val)
      }
      Vue.set(this.editSemester, name, val);
    },
  },
  filters: {
    startCase(data) {
      return data ? _.startCase(data) : data;
    },
    radioResponse(data) {
      const opt = {
        true: "Yes",
        false: "No",
      };
      return opt[data] || "--";
    },
    formatDate(date) {
      return date ? m(date).format("DD-MM-YYYY") : date;
    },
  },
};
</script>
<style lang="scss" scoped>
.w-98 {
  width: 98%;
}
.head {
  font-size: 12px;
  color: grey;
  margin: 6px 0;
}
.data {
  color: #000;
  font-weight: 400;
}
.multi-records .record {
  border-top: none;
  background: hsla(0, 0%, 83.5%, 0.12941176470588237);
  border-radius: 6px;
  border-bottom: 2px dotted #e1dede;
  margin-bottom: 3px;
}
.action-button .c-icon {
  width: 30px;
  height: 25px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
</style>

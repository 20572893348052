<template>
  <div class="course-option">
    <div class="d-flex justify-content-between">
      <div class="mt-2">
        <h6 class="text-primary">
          Course Options
          <span class="h6">
            [{{ courseOptionsInfo.length || "0" }} record{{
              courseOptionsInfo.length > 1 ? "s" : ""
            }}]</span
          >
        </h6>
      </div>
      <div>
        <CButton
          class="btn-primary small mx-2"
          style="height: 28px"
          v-c-tooltip="'Click to Add new option!'"
          v-if="!isEdit"
          @click="addNewOption"
          >Add</CButton
        >
        <span
          class="btn action-button mx-2"
          v-c-tooltip="{ content: 'Save', placement: 'left' }"
          v-if="isEdit"
          @click="submitOption"
        >
          <CIcon
            name="cil-save"
            class="active text-primary"
            style="margin-right: 20px"
          />
        </span>
        <span
          class="btn action-button mx-2"
          v-c-tooltip="{ content: 'Cancel', placement: 'left' }"
          v-if="isEdit"
          @click="cancelEdit"
        >
          <CIcon
            name="cil-x-circle"
            class="active text-primary"
            style="margin-right: 20px"
          />
        </span>
      </div>
    </div>
    <div v-if="isEdit" class="border-bottom" ref="form">
      <ValidationObserver ref="optionForm" v-slot="{ handleSubmit }">
        <form id="optionForm" @submit.prevent="handleSubmit(onSubmit)">
          <CRow>
            <CCol md="4">
              <CRow class="mb-3">
                <label class="col-lg-12 col-md-12 required">Option</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <TextInput
                      name="option_name"
                      :value="editOption.option_name"
                      @input="handleInput"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
            </CCol>
            <CCol md="4">
              <CRow class="mb-3">
                <label class="col-lg-12 col-md-12">Delivery Mode</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <Select
                    name="delivery_mode"
                    :value="editOption.delivery_mode"
                    :options="
                      options && options['courseDeliveryMode']
                        ? options['courseDeliveryMode']
                        : []
                    "
                    :multiple="false"
                    :clearable="false"
                    @input="handleChangeSelect"
                  />
                </div>
              </CRow>
            </CCol>
            <CCol md="4">
              <CRow class="mb-3">
                <label class="col-lg-12 col-md-12">Lessons</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <TextInput
                    name="lessons"
                    :value="editOption.lessons"
                    @input="handleInput"
                  />
                </div>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="4">
              <CRow class="mb-3">
                <label class="col-lg-12 col-md-12">Retail Price</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <TextInput
                    name="retail_price"
                    :value="editOption.retail_price"
                    @input="handleInput"
                    type="Number"
                  />
                </div>
              </CRow>
            </CCol>
            <CCol md="4">
              <CRow class="mb-3">
                <label class="col-lg-12 col-md-12">Partner Price</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <TextInput
                    name="partner_price"
                    :value="editOption.partner_price"
                    @input="handleInput"
                    type="Number"
                  />
                </div>
              </CRow>
            </CCol>
            <CCol md="4">
              <CRow class="mb-3">
                <label class="col-lg-12 col-md-12">Currency</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <Select
                    name="currency_id"
                    :value="editOption.currency_id"
                    @change="handleChangeSelect"
                    :options="
                      options && options['currency'] ? options['currency'] : []
                    "
                    :taggable="false"
                    :clearable="false"
                  />
                </div>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="4">
              <CRow md="3">
                <label class="col-lg-12 col-md-12">Training Type</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <Select
                    name="training_type_id"
                    :value="editOption.training_type_id"
                    @change="handleChangeSelect"
                    :options="
                      options && options['trainingTypes']
                        ? options['trainingTypes']
                        : []
                    "
                    :taggable="false"
                    :clearable="false"
                  />
                </div>
              </CRow>
            </CCol>
            <CCol md="4">
              <CRow class="mb-3">
                <label class="col-lg-12 col-md-12">Practice Test Inc.</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <RadioButton
                    name="practice_test_inc"
                    :value="editOption.practice_test_inc"
                    :options="
                      options && options['boolean'] ? options['boolean'] : []
                    "
                    @change="handleRadioButton"
                  />
                </div>
              </CRow>
            </CCol>
          </CRow>
        </form>
      </ValidationObserver>
    </div>
    <div class="multi-records">
      <div
        v-for="(option, index) in courseOptionsInfo"
        :key="index"
        class="record border-dotted-bottom px-3 pt-1"
      >
        <div class="d-flex justify-content-between">
          <div class="w-98">
            <CRow>
              <CCol md="4">
                <p class="head">Option</p>
                <p class="data">{{ option.option_name || "--" }}</p>
              </CCol>
              <CCol md="4">
                <p class="head">Delivery Mode</p>
                <p class="data">{{ option.delivery_mode | startCase }}</p>
              </CCol>
              <CCol md="4">
                <p class="head">Lessons</p>
                <p class="data">{{ option.lessons || "--" }}</p>
              </CCol>
              <CCol md="4">
                <p class="head">Retail Price</p>
                <p class="data">
                  {{ option.retail_price || "--" }}
                  {{
                    option.retail_price ? option.currency?.currency_code : ""
                  }}
                </p>
              </CCol>
              <CCol md="4">
                <p class="head">Partner Price</p>
                <p class="data">
                  {{ option.partner_price || "--" }}
                  {{
                    option.partner_price ? option.currency?.currency_code : ""
                  }}
                </p>
              </CCol>
              <CCol md="4">
                <p class="head">Training Type</p>
                <p class="data">
                  {{
                    option.training_type
                      ? option.training_type.training_type
                      : "--"
                  }}
                </p>
              </CCol>
              <CCol md="4">
                <p class="head">Practice Test Inc.</p>
                <p class="data">
                  {{ option.practice_test_inc | radioResponse }}
                </p>
              </CCol>
            </CRow>
          </div>
          <div class="text-center">
            <CButton
              class="icon icon-edit mb-2"
              v-c-tooltip="{ content: 'Edit', placement: 'left' }"
              @click="enableEditOption(option)"
            >
            </CButton>
            <!-- <CButton
              class="icon"
              v-c-tooltip="{ content: 'Delete', placement: 'left' }"
              @click="deleteOption(option)"
            >
              <i class="fas fa-trash-alt" />
            </CButton> -->
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-if="deleteConfirmModal.isShowPopup"
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import Vue from "vue";
import CourseViewMixin from "@/containers/Course/ViewCourse/CourseViewMixin.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CourseViewOption",
  mixins: [CourseViewMixin],
  props: {
    courseOptionsInfo: {
      type: Array,
      default: () => [],
    },
    courseId: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      isEdit: false,
      editOption: null,
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        data: null,
      },
    };
  },
  computed: {
    options() {
      return {
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
        courseDeliveryMode: this.courseDeliveryMode,
        currency: this.getCurrency,
        trainingTypes: this.trainingTypes,
      };
    },
  },
  methods: {
    ...mapActions(["updateCourseOption", "createCourseOption"]),
    async submitOption() {
      this.tooltipHide();
      const isValid = await this.$refs.optionForm.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      }
      const payload = {
        ...this.editOption,
        training_course_id: this.courseId,
        training_type_id: this.editOption.training_type_id?.code || null,
        currency_id: this.editOption?.currency_id?.code || null,
        practice_test_inc: this.editOption?.practice_test_inc,
        delivery_mode: this.editOption?.delivery_mode?.code || null,
      };
      const { training_course_opt_id } = this.editOption;
      let appendAction = [];
      if (training_course_opt_id) {
        appendAction = [
          this.updateCourseOption({ payload, training_course_opt_id }),
        ];
      } else {
        appendAction = [this.createCourseOption(payload)];
      }
      Promise.all(appendAction).then((res) => {
        this.cancelEdit();
      });
    },
    addNewOption() {
      this.tooltipHide();
      this.editOption = {};
      this.isEdit = true;
    },
    cancelEdit() {
      this.tooltipHide();
      this.isEdit = false;
      this.editOption = null;
    },
    enableEditOption(data) {
      this.tooltipHide();
      this.editOption = {
        ...data,
        delivery_mode: data?.delivery_mode
          ? { code: data.delivery_mode, label: _.startCase(data.delivery_mode) }
          : null,
        currency_id: data?.currency_id
          ? { code: data.currency_id, label: data?.currency?.currency_code }
          : null,
        training_type_id: data?.training_type_id
          ? {
              code: data.training_type_id,
              label: data.training_type.training_type,
            }
          : null,
      };
      this.isEdit = true;
      setTimeout(() => {
        this.$refs.form?.scrollIntoView({ behavior: "smooth" });
      }, 10);
    },
    deleteOption(data) {
      this.tooltipHide();
      if (this.courseOptionsInfo.length < 2) {
        this.showToast({
          class: "bg-danger text-white",
          message: "At least one option required!",
        });
        return;
      }
      this.deleteConfirmModal.modalTitle = "NOTIFICATION";
      this.deleteConfirmModal.modalContent =
        "Are you sure you want to delete this Option?";
      this.deleteConfirmModal.isShowPopup = true;
      this.deleteConfirmModal.data = data;
    },
    deleteModalCallBack(action) {
      if (action === "Confirm") {
        const { data } = this.deleteConfirmModal;
      }
      this.deleteConfirmModal.isShowPopup = false;
      this.deleteConfirmModal.data = null;
    },
    handleInput(name, value) {
      Vue.set(this.editOption, name, value);
    },
    handleChangeSelect(name, value, index) {
      Vue.set(this.editOption, name, value);
    },
    handleRadioButton(name, value) {
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.editOption, name, val);
    },
  },
  filters: {
    startCase(data) {
      return data ? _.startCase(data) : data;
    },
    radioResponse(data) {
      const opt = {
        true: "Yes",
        false: "No",
      };
      return opt[data] || "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.w-98 {
  width: 98%;
}
.head {
  font-size: 12px;
  color: grey;
  margin: 6px 0;
}
.data {
  color: #000;
  font-weight: 400;
}
.multi-records .record {
  border-top: none;
  background: hsla(0, 0%, 83.5%, 0.12941176470588237);
  border-radius: 6px;
  border-bottom: 2px dotted #e1dede;
  margin-bottom: 3px;
}
.action-button .c-icon {
  width: 30px;
  height: 25px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
</style>

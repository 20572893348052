<template>
  <div class="course-detail">
    <div class="d-flex justify-content-between">
      <div class="mt-2">
        <h6 class="text-primary">Course Details</h6>
      </div>
      <div class="d-flex justify-content-end">
        <span
          class="btn action-button"
          v-c-tooltip="{ content: 'Save', placement: 'left' }"
          @click="updateCourseDetail"
        >
          <CIcon
            name="cil-save"
            class="active text-primary"
            style="margin-right: 20px"
          />
        </span>
      </div>
    </div>
    <CRow>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12 required">Course Type</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <Select
                name="course_test_type_id"
                :value="courseDetail.course_test_type_id"
                :options="
                  options && options['course_type']
                    ? options['course_type']
                    : []
                "
                :multiple="false"
                :clearable="false"
                @input="handleChangeSelect"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12 required">Healthcare Sector</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <Select
                name="healthcare_sector"
                :value="courseDetail.healthcare_sector"
                :options="
                  options && options['courseHealthcareSector']
                    ? options['courseHealthcareSector']
                    : []
                "
                :multiple="false"
                :clearable="false"
                @input="handleChangeSelect"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Reference</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <TextInput
              name="course_ref"
              :value="courseDetail.course_ref"
              @input="handleInput"
            />
          </div>
        </CRow>
      </CCol>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12 required">Title</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <TextInput
              name="course_title"
              :value="courseDetail.course_title"
              @input="handleInput"
            />
          </div>
        </CRow>
      </CCol>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12 required">Language</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <Select
                name="language_id"
                :value="courseDetail.language_id"
                :options="
                  options && options['languages'] ? options['languages'] : []
                "
                :multiple="false"
                :clearable="false"
                @input="handleChangeSelect"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
      <CCol md="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Exam/Test Name</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              name="language_exam"
              :value="courseDetail.language_exam"
              @change="handleChangeSelect"
              :options="(options && options['languageExam']) || []"
              :clearable="true"
            />
          </div>
        </CRow>
      </CCol>
      <CCol md="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">CEFR Level</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <TextInput
              name="cefr_level"
              :value="courseDetail.cefr_level"
              @input="handleInput"
            />
          </div>
        </CRow>
      </CCol>
      <CCol md="12">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Course Synopsis</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <TextAreaInput
              name="course_synopsis"
              :value="courseDetail.course_synopsis"
              @input="handleInput"
            />
          </div>
        </CRow>
      </CCol>
      <CCol md="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Learning Hours</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="numeric" v-slot="{ errors }">
              <TextInput
                name="learning_hours"
                :value="courseDetail.learning_hours"
                @input="handleInput"
                type="number"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
      <CCol md="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Duration Access(Months)</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="numeric" v-slot="{ errors }">
              <TextInput
                name="duration_months"
                :value="courseDetail.duration_months"
                @input="handleInput"
                type="number"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
      <CCol md="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">CPD Points</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="numeric" v-slot="{ errors }">
              <TextInput
                name="cpd_points"
                :value="courseDetail.cpd_points"
                @input="handleInput"
                type="number"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import CourseViewMixin from "@/containers/Course/ViewCourse/CourseViewMixin.js";
import { mapActions } from "vuex";

export default {
  name: "CourseDetailView",
  mixins: [CourseViewMixin],
  props: {
    courseDetailInfo: {
      type: Object,
      default: () => {},
    },
    courseId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      courseDetail: {},
      payload: {},
    };
  },
  computed: {
    options() {
      return {
        course_type: this.courseTypes,
        languages: this.locationLanguageReOrder,
        courseHealthcareSector: this.courseHealthcareSector,
        languageExam: this.getLanguageTest
      };
    },
  },
  methods: {
    ...mapActions(["updateCourse"]),
    handleChangeSelect(name, value) {
      Vue.set(this.courseDetail, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
    },
    handleInput(name, value) {
      Vue.set(this.courseDetail, name, value);
      Vue.set(this.payload, name, value);
    },
    updateParentData() {
      const formName = "courseDetail";
      this.$emit("syncData", formName, this.payload[formName]);
    },
    updateCourseDetail() {
      const payload = {
        ...this.payload,
        course_provider: this.courseDetailInfo?.course_provider,
      };
      this.updateCourse({ training_course_id: this.courseId, payload });
    },
    initCourseDetail() {
      const data = this.courseDetailInfo;
      const courseType = data?.course_test_types
        ? {
            code: data?.course_test_type_id,
            label: data?.course_test_types?.course_test_type,
          }
        : null;
      this.handleChangeSelect("course_test_type_id", courseType);

      const healthcareSector = data.healthcare_sector
        ? {
            code: data.healthcare_sector,
            label: _.startCase(data.healthcare_sector),
          }
        : null;
      this.handleChangeSelect("healthcare_sector", healthcareSector);

      const test_id = data.test ? {
        code: data.test.test_id,
        label: data.test?.test_acronym && data.test?.test_name
                            ? `${data.test.test_acronym}-${data.test.test_name}`
                            : data.test?.test_name,
                    test_acronym: data.test?.test_acronym,
      }: null;
      this.handleChangeSelect("test_id", test_id);

      this.handleInput("course_ref", data.course_ref);
      this.handleInput("course_title", data.course_title);
      const language = data.language_id
        ? { code: data.language_id, label: data?.language?.language }
        : null;
      this.handleChangeSelect("language_id", language);
      this.handleInput("cefr_level", data.cefr_level);
      this.handleInput("course_synopsis", data.course_synopsis);
      this.handleInput("learning_hours", data.learning_hours);
      this.handleInput("duration_months", data.duration_months);
      this.handleInput("cpd_points", data.cpd_points);
    },
  },
  mounted() {
    this.initCourseDetail();
  },
};
</script>
